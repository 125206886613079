import { ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { DataService } from '@core/interfaces/data-service'
import { Dropdown, DropdownChangeEvent, DropdownModule } from 'primeng/dropdown'
import { Entity } from '@core/models/entity'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'
import { JsonPipe, NgClass, NgIf, NgTemplateOutlet, SlicePipe } from '@angular/common'
import { FormsModule, ValidationErrors } from '@angular/forms'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'
import { InputGroupModule } from 'primeng/inputgroup'
import { ButtonComponent } from '@app/components/uiux/button/button.component'
import { TooltipModule } from 'primeng/tooltip'
import { ControlErrorDirective } from '@core/directives/control-error.directive'

@Component({
  selector: 'tb-entity-search-select',
  standalone: true,
  templateUrl: 'entity-search-select.component.html',
  imports: [
    DropdownModule,
    NgIf,
    SlicePipe,
    NgClass,
    FormsModule,
    JsonPipe,
    ControlLabelComponent,
    InputGroupModule,
    ButtonComponent,
    TooltipModule,
    NgTemplateOutlet,
  ],
})
export class EntitySearchSelectComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('dropdown') dropdown!: Dropdown
  @ContentChild(ControlErrorDirective) controlErrorContent!: ControlErrorDirective

  @Input({ required: true }) dataService!: DataService
  @Input({ required: true }) optionLabel: string = ''
  @Input({ required: true }) label: string = ''
  @Input({ required: true }) invalid: boolean = false
  @Input({ required: true }) loadFullEntityOnSelect: boolean = true
  @Input() labelPrefixKey: string = ''
  @Input() required: boolean = false
  @Input() disabled: boolean = false
  @Input() style: object = {}
  @Input() value!: object | null
  @Input() openUrl: string = ''
  @Input() controlErrors: ValidationErrors | null = null

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>()

  // internal properties
  protected id: string
  protected keyword: string = ''
  protected options: Entity[] = []
  protected selectedOption: Entity | null = null
  protected inputChanged: Subject<string> = new Subject<string>()
  protected loading: boolean = false

  private dataSubscription!: Subscription

  constructor(private cd: ChangeDetectorRef) {
    this.id = Math.random().toString(36).substring(2)
  }

  ngOnInit(): void {
    this.inputChanged.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(keyword => {
      this.onKeywordChanged(keyword)
    })
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value && (changes['value'] && changes['value'].previousValue === null || changes['value'] && changes['value'].previousValue === '' || changes['value'] && changes['value'].previousValue === undefined)) {
      this.setInitialValue()
    }
  }

  onCleared(): void {
    this.selectedOption = null
    this.keyword = ''
    this.inputChanged.next('')

    if (this.dropdown.editableInputViewChild) {
      this.dropdown.editableInputViewChild.nativeElement.value = ''
    }
  }

  onChanged(event: DropdownChangeEvent): void {
    if (event.value === null) {
      this.onSelected.emit(event.value)
    }
    else if (typeof event.value === 'string') {
      // search mode
      this.keyword = event.value

      if (this.keyword && this.keyword.length > 2) {
        this.inputChanged.next(this.keyword)
      }
    }
    else if (typeof event.value === 'number') {
      // select mode
      this.selectedOption = event.value as any
    }
    else if (typeof event.value === 'object') {
      // load full entity from backend
      if (this.loadFullEntityOnSelect && typeof event.value.id !== 'undefined' && event.value.id > 0) {
        this.dataSubscription = this.dataService.getItem(event.value.id)
          .subscribe(entity => {
            this.onSelected.emit(entity)
          })
      } else {
        this.onSelected.emit(event.value)
      }
    }
  }

  onKeywordChanged(keyword: string): void {
    if (keyword.length > 2) {
      if (this.dataService.getCollectionSelect?.(keyword)) {
        this.loading = true
        this.options = []
        this.cd.markForCheck()

        this.dataSubscription = this.dataService.getCollectionSelect(keyword)
          .subscribe(options => {
            this.processOptions(options)
            this.loading = false
            this.cd.markForCheck()
          })
      } else {
        console.error('Method getCollectionSelect is no implemented in', this.dataService)
      }
    }
  }

  getLabel(item: any): string {
    if (!item[this.optionLabel]) return ''

    const label: string = item[this.optionLabel]

    if (this.labelPrefixKey.length > 0) {
      if (item[this.labelPrefixKey]) {
        return item[this.labelPrefixKey] + ' - ' + label
      }
    }

    return label
  }

  private setInitialValue(): void {
    this.selectedOption = this.value as Entity
    this.processOptions([ this.selectedOption ])
    this.cd.markForCheck()
  }

  protected onOpen(): void {
    if (this.selectedOption && this.openUrl.length > 0) {
      const win = window.open(location.origin + this.openUrl + this.selectedOption.id, '_blank')
      if (win) {
        win.focus()
      }
    }
  }

  private processOptions(options: Entity[]): void {
    if (this.labelPrefixKey.length > 0) {
      this.options = options.map((option: any) => {
        return {
          ...option,
          displayLabel: this.getLabel(option)
        }
      })
    } else {
      this.options = options
    }
  }

}

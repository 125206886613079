<div class="input-group">
	<tb-control-label [id]="id" [label]="label" [required]="required" [invalid]="invalid" />

	<p-inputNumber
		[(ngModel)]="value"
    [showButtons]="showButtons"
		[suffix]="suffix.length > 0 ? ' ' + suffix : ''"
    [min]="min"
    [max]="max"
		[readonly]="readonly"
		[allowEmpty]="false"
		[disabled]="disabled"
		[class]="invalid ? 'ng-dirty ng-invalid w-full' : 'w-full'"
    buttonLayout="horizontal"
    decrementButtonClass="btn-secondary"
    incrementButtonClass="btn-secondary"
    incrementButtonIcon="pi pi-plus"
    decrementButtonIcon="pi pi-minus"
  />

	<div class="input-error-message">
		<ng-container *ngIf="invalid">
			<span *ngIf="control.errors?.['required'] else validateBounds">Pflichtfeld</span>

			<ng-template #validateBounds>
				<span *ngIf="control.errors?.['min'] else validateMaxValue">
					Der akzeptierte Mindestwert wurde unterschritten.
				</span>

				<ng-template #validateMaxValue>
					<span *ngIf="control.errors?.['max']">Der maximal akzeptierte Wert wurde überschritten.</span>
				</ng-template>
			</ng-template>
		</ng-container>
  </div>
</div>

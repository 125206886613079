<div class="input-group">
	<tb-control-label [id]="id" [label]="label" [required]="required" [invalid]="invalid" />

	<p-inputGroup>
		<p-dropdown
			#dropdown
			dataKey="id"
			[id]="id"
			[(ngModel)]="selectedOption"
			[options]="options"
			[optionLabel]="labelPrefixKey.length > 0 ? 'displayLabel' : optionLabel"
			[editable]="true"
			[showClear]="true"
			[disabled]="disabled"
			[required]="required"
			[style]="style"
			[class]="invalid ? 'ng-dirty ng-invalid w-full' : 'w-full'"
			(onClear)="onCleared()"
			(onChange)="onChanged($event)"
		>
			<ng-template pTemplate="empty">
				<ng-container *ngIf="loading else elseIf">Lädt...</ng-container>
				<ng-template #elseIf>Keine Einträge vorhanden</ng-template>
			</ng-template>

			<ng-template pTemplate="item" let-item>
				<div class="item">
					{{ getLabel(item) }}
				</div>
			</ng-template>

			<ng-template pTemplate="selectedItem" let-item>
				<div class="item">
					{{ getLabel(item) }}
				</div>
			</ng-template>
		</p-dropdown>

		<tb-btn
			*ngIf="selectedOption && openUrl.length > 0"
			[secondary]="true"
			(onClick)="onOpen()"
			label=""
			icon="pi pi-external-link"
			pTooltip="Öffnen"
		/>
	</p-inputGroup>

	<div class="input-error-message">
		<ng-container *ngIf="controlErrorContent else elseBlock">
			<ng-container
				[ngTemplateOutlet]="controlErrorContent.templateRef"
				[ngTemplateOutletContext]="{ errors: controlErrors }"
			/>
		</ng-container>

		<ng-template #elseBlock>
			<ng-container *ngIf="invalid">
				<span *ngIf="required else elseIf0">Pflichtfeld</span>

				<ng-template #elseIf0>
					<span>Ungültiger Wert</span>
				</ng-template>
			</ng-container>
		</ng-template>
	</div>
</div>

import { booleanAttribute, Component, forwardRef, Input } from '@angular/core'
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InputNumberModule } from 'primeng/inputnumber'
import { NgClass, NgIf } from '@angular/common'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'
import { DropdownModule } from 'primeng/dropdown'

@Component({
  selector: 'tb-integer-input',
  standalone: true,
  imports: [
    FormsModule,
    InputNumberModule,
    NgClass,
    TooltipModule,
    NgIf,
    InputTextModule,
    ControlLabelComponent,
    DropdownModule,
  ],
  templateUrl: 'integer-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegerInputComponent),
      multi: true
    },
  ],
})
export class IntegerInputComponent extends BaseFormControlComponent {

  @Input({ transform: booleanAttribute }) showButtons: boolean = true
  @Input() minusAllowed: boolean = false
  @Input() suffix: string = ''
  @Input() min: number = 0
  @Input() max: number = 2147483647


  protected override init(): void {
    if (this.minusAllowed) {
      this.min = -1000000000
      this.max = 1000000000
    }
  }
}
